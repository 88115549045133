<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("GALLERY") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('GALLERY_SEARCH')"
          :searchFunc="allgallery"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <p class="table-count">{{ getTotalRowgallery }} {{ $t("GALLERY") }}</p>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("PICTURE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllgallery"
              :key="i"
              @click="handleUpdate(data)"
            >
              <td aria-colindex="2" role="cell">
                {{ data.titre }}
              </td>
              <td aria-colindex="3" role="cell">
                <img
                  v-if="data.image"
                  class="prodimg"
                  :src="`${galleryUrl}${data.image}`"
                  alt=""
                />
                <img
                  v-if="!data.image"
                  src="@/assets/noimg.jpg"
                  class="prodimg"
                  alt=""
                />
              </td>
              <td aria-colindex="3" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal
        id="ModelConfiggallery"
        ref="ModelConfiggallery"
        hide-footer
        @hide="v$.$reset()"
      >
        <template #modal-header="{ close }">
          <h5>{{ $t("EDIT") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="galleryModalNew" v-if="galleryToConfig">
          <div class="center">
            <b-form-group :label="$t('WORDING')">
              <b-form-input
                v-model="v$.galleryToConfig.titre.$model"
                :state="validateState('titre')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="titre-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.galleryToConfig.titre.$errors"
                id="titre-feedback"
              ></error-handle>
              <b-form-input v-model="galleryToConfig.titre"></b-form-input>
              <div v-if="erreurlist.titre" class="error-message">
                <ul v-if="Array.isArray(erreurlist.titre)">
                  <span v-for="(e, index) in erreurlist.titre" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.titre }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('PICTURE')">
              <b-form-file
                accept="image/*"
                v-model="galleryToConfig.image"
                placeholder=""
                drop-placeholder=""
                @input="filesize($event)"
              ></b-form-file>
            </b-form-group>
            <div v-if="showErrorAlert" class="error-message">
              {{ $t("SIZEMAX") }}
            </div>
          </div>

          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button variant="success" type="submit">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getLoadinggallery" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </form>
      </b-modal>

      <deleteModalVue
        :loader="getLoadinggallery"
        :elemToDelete="galleryToDelete"
        :elemDelete="delete_gallery"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowgallery"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowgallery"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadinggallery"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/gallerymodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchBar,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      erreurlist: {
        titre: null,
      },
      search: "",
      galleryToConfig: {
        titre: null,
      },
      galleryToDelete: null,
      page: 1,
      perPage: 10,
      favoris: null,
      galleryUrl: domains.gallery,
      errorD: null,
      errorU: null,
      showErrorAlert: false,
    };
  },
  validations() {
    return {
      galleryToConfig: {
        titre: { required, maxLength: maxLength(100) },
      },
    };
  },
  methods: {
    ...mapActions(["edit_gallery", "allgallery", "delete_gallery"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.galleryToConfig[name];
      return $dirty ? !$error : null;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },
    async searchgallery() {
      await this.allgallery({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.galleryToConfig = { ...CAT };
      this.galleryToConfig.image = null;
      this.$refs["ModelConfiggallery"].show();
    },
    handleDelete(CAT) {
      this.galleryToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    filesize(event) {
      if (event && event.size > 1048576) {
        this.showErrorAlert = true;
      } else {
        this.showErrorAlert = false;
      }
    },
    async galleryModalNew() {
      const isFormCorrect = await this.v$.galleryToConfig.$error;
      if (isFormCorrect) return;
      this.erreurlist = {
        titre: null,
      };
      this.errorU = null;
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.galleryToConfig.id);
      bodyFormData.append("titre", this.galleryToConfig.titre);
      if (this.galleryToConfig.image)
        bodyFormData.append("image", this.galleryToConfig.image);
      if (this.showErrorAlert == false) {
        await this.edit_gallery(bodyFormData)
          .then(() => {
            this.hideModal("ModelConfiggallery");
            this.errorU = null;
          })
          .catch((err) => {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorU = err;
            }
          });
      }
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    handleStore() {
      this.$root.$emit("bv::show::modal", "galleryModal");
    },

    pagination(paginate) {
      if (this.search === null || this.search === "") {
        this.page = paginate;
        this.allgallery({
          page: this.page,
          per_page: this.perPage,
        });
      } else {
        this.page = paginate;
        this.searchgallery();
      }
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.allgallery({ page: this.page, per_page: this.perPage });
    },
  },

  mounted() {
    this.setup(true);
  },

  computed: {
    ...mapGetters([
      "getAllgallery",
      "getLoadinggallery",
      "getTotalRowgallery",
      "getTabs",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  text-align: center !important;

  .icon_trie {
    color: #28367a;
  }
}

.prodimg {
  padding: 4px;
  background: #465081;
  max-width: 60px;
  max-height: 52px;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .messageError {
    top: 0;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
